import { render, staticRenderFns } from "./luckyDrawhistory.vue?vue&type=template&id=60218903&scoped=true&"
import script from "./luckyDrawhistory.vue?vue&type=script&lang=js&"
export * from "./luckyDrawhistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60218903",
  null
  
)

export default component.exports