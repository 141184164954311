import request from '../utils/request'

//获取活动列表
export function getActiveList(data) {
    return request({
        url: '/platform/activity/activity/data',
        method: 'get',
        data
    })
}
//新增活动
export function addActive(data) {
    return request({
        url: '/platform/activity/activity/addDo',
        method: 'post',
        data
    })
}
//编辑活动
export function editDo(data) {
    return request({
        url: '/platform/activity/activity/editDo',
        method: 'post',
        data
    })
}
//编辑活动
export function editState(data) {
    return request({
        url: '/platform/activity/activity/editState',
        method: 'post',
        data
    })
}
//删除活动
export function deleteActive(data) {
    return request({
        url: '/platform/activity/activity/delete',
        method: 'post',
        data
    })
}
//获取活动奖励
export function getActivedPrize(data) {
    return request({
        url: '/platform/activity/lucky/data',
        method: 'get',
        data
    })
}
//上传图片
export function uploadImg(form) {
    return request({
        url: '/open/file/upload/image',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data;charse=UTF-8'
        },
        data: form
    })
}
//抽奖记录
export function luckyHistory(data) {
    return request({
        url: '/platform/activity/lucky/log/data',
        method: 'get',
        data
    })
}

//分享记录
export function shareHistory(data) {
    return request({
        url: '/platform/activity/share/log/data',
        method: 'get',
        data
    })
}
//修改奖品
export function editActivePrize(data) {
    return request({
        url: '/platform/activity/lucky/editDo',
        method: 'post',
        data
    })
}
//新增奖品
export function addDoList(data) {
    return request({
        url: '/platform/activity/lucky/addDo',
        method: 'post',
        data
    })
}
//删除奖品
export function detelPrize(data) {
    return request({
        url: '/platform/activity/lucky/delete',
        method: 'post',
        data
    })
}

