
<template>

    <el-container class="page-container">
        <!-- 搜索栏 -->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-width="80px" label-position='left' :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <!--<el-col :xs="12" :sm="12" :md="8" :lg="6">-->
                    <!--<el-form-item label="用户电话" prop="mobile">-->
                    <!--<el-input v-model="query.mobile" size="medium" class="inner-input"></el-input>-->
                    <!--</el-form-item>-->
                    <!--</el-col>-->
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="用户昵称" prop="userName">
                            <el-input v-model="query.userName" size="medium" class="inner-input"></el-input>
                        </el-form-item>
                    </el-col> <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="参与活动" prop="actTitle">
                            <el-input v-model="query.actTitle" size="medium" class="inner-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="奖品名称" prop="luckyName">
                            <el-input v-model="query.luckyName" size="medium" class="inner-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="领取时间" prop="dateArr">
                            <el-date-picker class="inner-input"
                                            size="medium"
                                            v-model="query.dateArr"
                                            type="daterange"
                                            range-separator="至"
                                            :default-time="['00:00:00', '23:59:59']"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            @change="dateChange"
                                            value-format="timestamp">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="handleSearch">搜索
                        </el-button>
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="clearSearch">清除
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
            <br>
            <el-table
                    :data="userList.list"
                    tooltip-effect="light"
                    border
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="username"
                        label="用户名称">
                </el-table-column>
                <!--<el-table-column-->
                <!--label="手机号码">-->
                <!--<template slot-scope="scope">-->
                <!--{{scope.row.users?scope.row.users.mobile:''}}-->
                <!--</template>-->
                <!--</el-table-column>-->
                <el-table-column
                        prop="acttitle"
                        label="参与活动">
                </el-table-column>
                <el-table-column
                        prop="luckyname"
                        label="奖品">
                </el-table-column>
                <el-table-column
                        prop="create_date"
                        label="抽奖时间">
                </el-table-column>

            </el-table>
            <br>
            <!-- 分页 -->
            <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page="query.pageNumber"
                    :page-sizes="pageSizes"
                    :page-size="query.pageSize"
                    layout="total,sizes,prev, pager, next, jumper"
                    :total="userList.totalCount">
            </el-pagination>
        </el-main>
    </el-container>

</template>

<script>

    import * as activeApi from '../../api/activeManage';

    export default {
        data() {
            return {
                couponListArr: [],
                userList: {
                    list: []
                },
                info: {},
                loading: true,
                // 分页
                pageSizes: [10, 20, 30, 40],
                query: {
                    pageSizes: 10, // 分码
                    pageNumber: 1, // 每页条数
                    userName: '', // 用户昵称
                    dateArr: [],
                    luckyName: '',
                    actTitle:''
                },
                isSubmit: false,
                shop_list: []
            };
        },
        computed: {},
        methods: {

            formatStatus(val) {
                switch (val) {


                    case 1:
                        return {text: '未使用', type: 'success'};
                    case 2:
                        return {text: '已使用', type: 'danger'};
                    case 3:
                        return {text: '已过期', type: 'info'};
                }
            },
            dateChange() {
                if (!this.query.dateArr) {
                    this.query.start_time = '';
                    this.query.end_time = '';
                }
            },
            async handleSearch() {
                this.query.pageNumber = 1;
                this.couponGetList();
            },
            clearSearch() {
                this.$refs.searchForm.resetFields();
                this.handleSearch()
            },
            handleCencle(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false;
            },
            // 分页
            handleSizeChange(val) {
                this.query.pageSizes = val;
                this.query.pageNumber = 1;
                this.couponGetList();
            },
            handleCurrentChange(val) {
                this.query.pageNumber = val;
                this.couponGetList();
            },
            async couponGetList() {
                if (this.query.dateArr && this.query.dateArr.length) {
                    this.query.startTime = this.query.dateArr[0];
                    this.query.endTime = this.query.dateArr[1];
                } else {
                    this.query.startTime = '';
                    this.query.endTime = '';
                }
                console.log(this.query)
                let formData = {}
                for (let i in this.query) {
                    if (this.query[i] && i !== 'dateArr') {
                        formData[i] = this.query[i]
                    }
                }
                let {data} = await activeApi.luckyHistory(formData);
                this.userList = data;

            },

        },
        async mounted() {
            this.loading = false;
            this.couponGetList();
        }

    };
</script>

<style scoped>

</style>

